@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/Inter-Regular.ttf) format('truetype');
  font-weight: 400;
}